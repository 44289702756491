<template>
    <div id="faceErrorMessage" ref="faceErrorMessage">
        <a-modal
                :title="$t('faceErrorMessage.a1')"
                centered
                :width="500"
                :visible="visible"
                :getContainer="() => this.$refs.faceErrorMessage"
                @cancel="Cancel"
                :destroyOnClose="true"
        >
            <template slot="footer">
                <a-button key="submit" type="primary"  @click="Cancel" >{{$t("faceErrorMessage.a2")}}</a-button>
            </template>

            <div>
                <my-tabletitle style="background-color: #fbc4c4;font-weight: bold">{{this.faceErrorTitle}}</my-tabletitle>
                <a-table
                    :columns="faceErrorListTable"
                    :dataSource="faceErrorData"
                    :pagination="false"
                    bordered
                    :rowKey="(record) => record.NO"
                >
                </a-table>

            </div>

        </a-modal>
    </div>

</template>

<script>
    import tabletitle from "../../../components/Title/tabletitle";
    export default {
        name: "faceErrorMessage",
        props: {
            faceErrorData: {
                type: Array,
            },
            visible: {
                type: Boolean,
            },
            faceErrorTitle:{
                type: String,
            }
        },
        data(){
            return{
                faceErrorListTable:[
                    {
                        key:"1",
                        title: "NO",
                        width: 30,
                        align: "center",
                        dataIndex: "NO",
                        ellipsis: true,
                    },
                    {
                        key:"2",
                        title: "IP",
                        width: 40,
                        align: "center",
                        dataIndex: "IP",
                        ellipsis: true,
                    },
                    {
                        key:"3",
                        title: "msg",
                        width: 40,
                        align: "center",
                        dataIndex: "msg",
                        ellipsis: true,
                    }

                ]
            }
        },

        methods: {
            // 弹窗关闭方法
            Cancel() {
                this.$emit("shut");
            },
        },

        components: {
            "my-tabletitle": tabletitle,
        },
    }
</script>

<style scoped>
    #faceErrorMessage {
        width: 300px;
        padding: 0px 10% 20px 20px;
        margin-bottom: 40px;
        white-space: nowrap;
        overflow: hidden;
    }
</style>