<!--人员管理--关联站点数据查询-->
<template>
  <div id="siteSelect" ref="siteSelect">
    <a-modal :title="$t('information.a207')" centered :width="900" :visible="visible" :getContainer="() => this.$refs.siteSelect" @cancel="Cancel" :destroyOnClose="true" v-drag>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="architectureSet" :disabled="disabled"> {{ $t("information.a208") }} </a-button>
        <a-button key="back" @click="Cancel"> {{ $t("information.a209") }} </a-button>
      </template>
      <div class="modal" v-loading="arealoading" :element-loading-text="$t('information.a1')">
        <div class="modal_main">
          <my-tabletitle>{{ $t("information.a210") }}</my-tabletitle>
          <a-table :columns="ArchitectureTableTitle" :dataSource="allArchitectureList" :scroll="{ y: 240 }" :pagination="pagination" :rowKey="(record) => record.siteId">
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => ArchitectureAdd(record)"> {{ $t("information.a211") }} </a-button>
            </template>
          </a-table>
          <a-divider />
          <my-tabletitle>{{ $t("information.a212") }}</my-tabletitle>
          <a-table :columns="ArchitectureTableTitle" :dataSource="registerArchitectureList" :scroll="{ y: 240 }" :pagination="pagination" :rowKey="(record) => record.siteId">
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => ArchitectureDelete(record)"> {{ $t("information.a213") }} </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import { getRelationSiteInfo, matchAccessRole } from "../../../api/info";

export default {
  props: {
    siteId: {
      defined: "",
    },
    visible: {
      type: Boolean,
    },
    siteList: {
      type: Array,
    },
    Type: {
      type: Number,
    },
    elevatorList: {
      type: Array,
    },
    personGrId:{
      type:String
    }
  },
  data() {
    return {
      arealoading: false,
      ArchitectureName: "",
      ArchitectureSite: "",
      ArchitectureType: "",
      ArchitectureNo: "",
      allArchitectureList: [],
      registerArchitectureList: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("information.a214")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      ArchitectureTableTitle: [
        {
          title: "NO",
          customRender: (text, record, index) => {
            if (this.pagination == undefined) {
              return `${
                (this._parentVnode.child.pagination.defaultCurrent - 1) *
                  this._parentVnode.child.pagination.pageSize +
                index +
                1
              }`;
            } else {
              return `${
                (this.pagination.defaultCurrent - 1) *
                  this.pagination.pageSize +
                index +
                1
              }`;
            }
          },
          width: 60,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a215");
            } else {
              return this.parent.$t("information.a215");
            }
          },
          dataIndex: "siteName",
          width: 250,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a216");
            } else {
              return this.parent.$t("information.a216");
            }
          },
          customRender: (text, record, index) => {
            if (record.address == null) {
              return "--";
            } else {
              return record.address;
            }
          },
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a217");
            } else {
              return this.parent.$t("information.a217");
            }
          },
          customRender: (text, record, index) => {
            if (record.classification == null) {
              return "--";
            } else {
              return record.classification;
            }
          },
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a218");
            } else {
              return this.parent.$t("information.a218");
            }
          },
          dataIndex: "clientManagementText",
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a188");
            } else {
              return this.parent.$t("information.a188");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          align: "center",
        },
      ],
      disabled:false
    };
  },
  mounted() {
    this.arealoading = true
    let siteIds = ''
    // 类型12是电梯站点
    if(this.Type !== 12){
      siteIds = this.siteList.map((item) => { return item.siteId }).join(',')
    }else{
      siteIds = this.elevatorList.map((item) => { return item.siteId }).join(',')
    }
    let data = {
      clientId: this.$store.getters.clientId,
      siteIds,
      contractType: this.Type
    }
    getRelationSiteInfo(data)
      .then((res) => {
        this.arealoading = false;
        const { data: { siteInfoBeans, registeredSiteInfoBeans } } = res
        this.allArchitectureList = siteInfoBeans
        this.registerArchitectureList = registeredSiteInfoBeans
      })
      .catch((err) => {
        console.log(err);
        this.arealoading = false;
      });
  },
  methods: {
    // 弹窗关闭方法
    Cancel() {
      this.$emit("shut");
    },
    // 注册站点列表设置方法
    architectureSet() {
      const value = this.Type
      console.log(value)
      // 类型12是电梯站点
      if (value !== 12) {
        this.arealoading = true
        this.disabled = true
        const arr = this.siteList.map((item)=> { return item.siteId })
        const newData = this.registerArchitectureList.filter((item)=> arr.indexOf(item.siteId) == -1 )
        const siteIds = newData.map((item)=>{ return item.siteId })
        const personGrId = this.personGrId
        const data = { siteIds,personGrId }
        matchAccessRole(data)
        .then(res=>{
          console.log(res)
          const { data } = res
          const patchData = data.map((item)=>{
            if(item.accessRoleList.length > 5){
              item.accessRoleList = item.accessRoleList.splice(6,accessRoleList.length - 5)
              return item
            }else{
              return item
            }
          })
          const registerArchitectureList = [...this.siteList,...patchData]
          console.log(registerArchitectureList)
          this.registerArchitectureList = registerArchitectureList
          this.arealoading = false
          this.disabled = false
          this.$emit("shut")
          this.$emit("selectData", this.registerArchitectureList)
        })
        .catch(err=>{
          console.log(err)
          this.arealoading = false
          this.disabled = false
        })
      } else {
        const elevatorList = this.elevatorList;
        const list = this.registerArchitectureList;
        if (elevatorList.length == 0) {
          for (let i = 0; i < list.length; i++) {
            console.log(list[i]);
            list[i].elevatorRoomBeanList = [];
          }
        } else if (elevatorList.length !== 0 && list.length !== 0) {
          for (let i = 0; i < list.length; i++) {
            let result = elevatorList.findIndex(
              (row) => row.siteId === list[i].siteId
            );
            console.log(result);
            if (result !== -1) {
              list[result] = elevatorList[i];
            } else {
              list[i].elevatorRoomBeanList = [];
            }
          }
        }
        this.$emit("shut");
        this.$emit("selectData", this.registerArchitectureList);
      }
    },
    ArchitectureAdd(value) {
      const id = value.siteId;
      let list = this.allArchitectureList;
      this.registerArchitectureList.push(value);
      let result = list.findIndex((row) => row.siteId === id);
      list.splice(result, 1);
    },
    ArchitectureDelete(value) {
      const id = value.siteId;
      let list = this.registerArchitectureList;
      this.allArchitectureList.push(value);
      let result = list.findIndex((row) => row.siteId === id);
      list.splice(result, 1);
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 0px;
}
/* .modal {
  height: 500px;
  overflow: hidden;
} */
.modal_main {
  height: 500px;
  padding: 20px;
  overflow: auto;
}
</style>