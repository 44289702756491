 <!--人员管理--电梯通行模式数据查询-->
<template>
  <div id="elevatorSelect" ref="elevatorSelect">
    <a-modal :title="$t('information.a228')" centered :width="900" :visible="visible" :getContainer="() => this.$refs.elevatorSelect" @cancel="Cancel" :destroyOnClose="true" v-drag>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="architectureSet"> {{ $t("information.a208") }} </a-button>
        <a-button key="back" @click="Cancel"> {{ $t("information.a209") }} </a-button>
      </template>
      <div class="modal" v-loading="arealoading" :element-loading-text="$t('information.a1')">
        <div class="modal_main">
          <my-tabletitle>{{ $t("information.a229") }}</my-tabletitle>
          <a-table :columns="ArchitectureTableTitle" :dataSource="allArchitectureList" :scroll="{ y: 240 }" :pagination="pagination" :rowKey="(record) => record.rm_ID">
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => ArchitectureAdd(record)"> {{ $t("information.a211") }} </a-button>
            </template>
          </a-table>
          <a-divider />
          <my-tabletitle>{{ $t("information.a230") }}</my-tabletitle>
          <a-table :columns="ArchitectureTableTitle" :dataSource="registerArchitectureList" :scroll="{ y: 240 }" :pagination="false" :rowKey="(record) => record.rm_ID">
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => ArchitectureDelete(record)"> {{ $t("information.a213") }} </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import { getroomList } from "../../../api/info";

export default {
  props: {
    siteId: {
      defined: "",
    },
    visible: {
      type: Boolean,
    },
    elevatorList: {
      type: Array,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      arealoading: false,
      allArchitectureList: [],
      allArchitectureListCopy: [],
      registerArchitectureList: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("information.a214")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      ArchitectureTableTitle: [
        {
          title: "NO",
          customRender: (text, record, index) => {
            if (this.pagination == undefined) {
              return `${
                (this._parentVnode.child.pagination.defaultCurrent - 1) *
                  this._parentVnode.child.pagination.pageSize +
                index +
                1
              }`;
            } else {
              return `${
                (this.pagination.defaultCurrent - 1) *
                  this.pagination.pageSize +
                index +
                1
              }`;
            }
          },
          width: 60,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a215");
            } else {
              return this.parent.$t("information.a215");
            }
          },
          dataIndex: "rm_ShowName",
          width: 250,
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a231");
            } else {
              return this.parent.$t("information.a231");
            }
          },
          dataIndex: "rm_floor",
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a232");
            } else {
              return this.parent.$t("information.a232");
            }
          },
          dataIndex: "rm_num",
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a188");
            } else {
              return this.parent.$t("information.a188");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
        },
      ],
    };
  },
  mounted() {
    this.arealoading = true;
    let _val = JSON.stringify(this.data.elevatorRoomBeanList);
    let val = JSON.parse(_val);
    this.registerArchitectureList = val;
    const data = { siteId: this.data.siteId };
    getroomList(data)
      .then((res) => {
        this.arealoading = false;
        const { data } = res;
        const allArchitectureListCopy = JSON.parse(JSON.stringify(data));
        const allArchitectureList = allArchitectureListCopy.filter(item => {
          return !val.some(_item => _item.rm_ID === item.rm_ID);
        });
        this.allArchitectureListCopy = allArchitectureListCopy;
        this.allArchitectureList = allArchitectureList;
      })
      .catch((err) => {
        console.log(err);
        this.arealoading = false;
      });
  },
  methods: {
    // 弹窗关闭方法
    Cancel() {
      this.$emit("shut");
    },
    // 注册电梯列表数据返回
    architectureSet() {
      this.$emit("shut");
      this.$emit("elevatorDate", this.registerArchitectureList);
    },
    // 添加按钮功能
    ArchitectureAdd(value) {
      const length = this.registerArchitectureList.length;
      if (length < 5) {
        const allArchitectureList = this.allArchitectureList;
        const filterList = allArchitectureList.filter(item => { return item.rm_ID != value.rm_ID });
        this.allArchitectureList = filterList;
        this.registerArchitectureList.push(value);
      } else {
        this.$warning({
          title: this.$t("information.a226"),
          centered: true,
          content: this.$t("information.a227"),
        });
      }
    },
    // 删除按钮功能
    ArchitectureDelete(value) {
      const allArchitectureListCopy = this.allArchitectureListCopy;
      const registerArchitectureList = this.registerArchitectureList;
      const filterList = registerArchitectureList.filter(item => { return item.rm_ID != value.rm_ID });
      const allArchitectureList = allArchitectureListCopy.filter(item => { return !filterList.some(_item => _item.rm_ID === item.rm_ID) });
      this.allArchitectureList = allArchitectureList;
      this.registerArchitectureList = filterList;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 0px;
}
.modal_main {
  height: 500px;
  padding: 20px;
  overflow: auto;
}
</style>