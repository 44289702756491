<!--人员管理--未使用卡查询选择-->
<template>
  <div id="cardSelect" ref="cardSelect">
    <!-- 普通卡选择弹框 -->
    <a-modal
      :title="$t('information.a169')"
      centered
      :width="900"
      :visible="visible"
      :getContainer="() => this.$refs.cardSelect"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a51") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <div class="queryBar">
          <a-row :gutter="16" type="flex" align="bottom">
            <a-col :span="5">
              <a-input
                allow-clear
                v-model="cardFaceNo"
                :placeholder="$t('information.a172')"
                style="width: 100%"
              />
            </a-col>
            <a-col :span="5">
              <a-input
                allow-clear
                v-model="cardNo"
                :placeholder="$t('information.a170')"
                style="width: 100%"
              />
            </a-col>
            <a-col :span="3">
              <a-button
                type="primary"
                style="width: 120px"
                @click="cardinquire"
              >
                {{ $t("information.a174") }}
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="cardselect_main">
          <my-tabletitle>{{ $t("information.a175") }}</my-tabletitle>
          <a-table
            :columns="columns"
            :dataSource="cardList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.cardId"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-icon
                type="select"
                :style="{
                  fontSize: '20px',
                  color: '#7682CE',
                }"
                @click="selectCard(record)"
              />
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import { getUnusedCard } from "../../../api/info";

export default {
  props: {
    siteId: {
      defined: "",
    },
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      cardFaceNo: "",
      cardNo: "",
      cardList: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => `共 ${total} 条数据`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) => {
            if (this.pagination == undefined) {
              return `${
                (this._parentVnode.child.pagination.defaultCurrent - 1) *
                  this._parentVnode.child.pagination.pageSize +
                index +
                1
              }`;
            } else {
              return `${
                (this.pagination.defaultCurrent - 1) *
                  this.pagination.pageSize +
                index +
                1
              }`;
            }
          },
          width: 60,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a176");
            } else {
              return this.parent.$t("information.a176");
            }
          },
          dataIndex: "cardFaceNo",
          width: 130,
          ellipsis: true,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a177");
            } else {
              return this.parent.$t("information.a177");
            }
          },
          dataIndex: "cardNo",
          width: 130,
          ellipsis: true,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a178");
            } else {
              return this.parent.$t("information.a178");
            }
          },
          customRender: (text, record, index) => {
            switch (record.status) {
              case 1:
                return this.$t("information.a179");
                break;
              case 2:
                return this.$t("information.a180");
                break;
              case 3:
                return this.$t("information.a181");
                break;
              case 4:
                return this.$t("information.a182");
                break;
              case 5:
                return this.$t("information.a183");
                break;
              default:
                return "--";
                break;
            }
          },
          width: 130,
          ellipsis: true,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a184");
            } else {
              return this.parent.$t("information.a184");
            }
          },
          customRender: (text, record, index) => {
            switch (record.temporaryCard) {
              case 0:
                return this.$t("information.a185");
                break;
              case 1:
                return this.$t("information.a186");
                break;
              default:
                return "--";
                break;
            }
          },
          width: 90,
          ellipsis: true,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a187");
            } else {
              return this.parent.$t("information.a187");
            }
          },
          dataIndex: "activationTime",
          ellipsis: true,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a188");
            } else {
              return this.parent.$t("information.a188");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          align: "center",
        },
      ],
    };
  },
  methods: {
    // 弹窗关闭方法
    Cancel() {
      this.$emit("shut");
    },
    // 弹窗选择卡方法
    selectCard(value) {
      this.$emit("selectData", value);
      this.$emit("shut");
    },
    // 未使用卡数据获取方法
    cardinquire() {
      this.loading = true;
      let data = {
        cardFaceNo: this.cardFaceNo,
        cardNo: this.cardNo,
        siteId: this.siteId,
      };
      console.log(data);
      getUnusedCard(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          const { data } = res;
          this.cardList = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.header {
  margin-bottom: 20px;
}
.queryBar {
  min-width: 1250px;
  padding: 10px;
  min-width: 850px;
  background-color: aliceblue;
  border: 1px solid rgba(12, 12, 12, 0.1);
  margin-bottom: 10px;
}
</style>