<!--人员管理--通行模式选择-->
<template>
  <div id="PassageModeSelection" ref="PassageModeSelection">
    <a-modal
      :title="$t('information.a219')"
      centered
      :width="800"
      :visible="visible"
      :getContainer="() => this.$refs.PassageModeSelection"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
    <!-- 弹框底部 -->
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a190") }}
        </a-button>
        <a-button key="submit" type="primary" @click="dataSet">
          {{ $t("information.a191") }}
        </a-button>
      </template>
      <!-- 弹框内容区域 -->
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <div class="pop_main">
          <a-input-search :placeholder="$t('information.a274')" style="width: 200px;margin-bottom:15px" allow-clear @change="onChange" />
          <my-tabletitle>{{ $t("information.a220") }}</my-tabletitle>
          <a-table
            :columns="columns1"
            :dataSource="searchList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.id"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => passageAdd(record)">{{
                $t("information.a211")
              }}</a-button>
            </template>
          </a-table>
          <a-divider />
          <my-tabletitle>{{ $t("information.a220") }}</my-tabletitle>
          <a-table
            :columns="columns2"
            :dataSource="selectList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.id"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => passageDelete(record)">{{
                $t("information.a213")
              }}</a-button>
            </template>
            <template slot="activationTime" slot-scope="text, record">
              <a-date-picker v-model="record.activationTime" />
            </template>
            <template slot="expirationTerm" slot-scope="text, record">
              <a-date-picker v-model="record.expirationTerm">
                <template slot="renderExtraFooter"> </template>
              </a-date-picker>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import { getThroughList } from "../../../api/info";
import moment from "moment";

export default {
  components: {
    "my-tabletitle": tabletitle,
  },
  props: {
    data: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    siteList: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      allList: [],
      searchList:[],
      selectList: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("information.a214")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns1: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 100,
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a221");
            } else {
              return this.parent.$t("information.a221");
            }
          },
          dataIndex: "name",
          align: "center",
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a188");
            } else {
              return this.parent.$t("information.a188");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
        },
      ],
      columns2: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a221");
            } else {
              return this.parent.$t("information.a221");
            }
          },
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a222");
            } else {
              return this.parent.$t("information.a222");
            }
          },
          dataIndex: "activationTime",
          scopedSlots: { customRender: "activationTime" },
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a223");
            } else {
              return this.parent.$t("information.a223");
            }
          },
          dataIndex: "expirationTerm",
          scopedSlots: { customRender: "expirationTerm" },
          ellipsis: true,
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a188");
            } else {
              return this.parent.$t("information.a188");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
        },
      ],
    };
  },
  watch: {
    data: {
      handler: function (newValue, oldValue) {
        let _val = JSON.stringify(newValue);
        let val = JSON.parse(_val);
        if (val.accessRoleList !== undefined) {
          this.selectList = val.accessRoleList;
          for (let i = 0; i < this.selectList.length; i++) {
            this.selectList[i].activationTime = moment(
              this.selectList[i].activationTime
            );
            this.selectList[i].expirationTerm = moment(
              this.selectList[i].expirationTerm
            );
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  // 加载通行模式列表数据
  mounted() {
    this.loading = true;
    const data = {
      siteId: this.data.siteId,
    };
    getThroughList(data)
      .then((res) => {
        this.loading = false;
        const {
          data: { accessRoleList },
        } = res;
        this.allList = accessRoleList
        this.searchList = accessRoleList
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    moment,
    // 搜索方法
    onChange(e) {
      const value = e.target.value
      const data = this.allList.filter((item) => {
        return item.name.indexOf(value) !== -1
      })
      this.searchList = data
    },
    // 弹框关闭方法
    Cancel() {
      this.$emit("shut");
    },
    // 数据设置方法
    dataSet() {
      console.log(this.selectList);
      let _val = JSON.stringify(this.selectList);
      let val = JSON.parse(_val);
      console.log(val);
      for (let i = 0; i < val.length; i++) {
        val[i].activationTime = moment(val[i].activationTime).format(
          "YYYY-MM-DD"
        );
        val[i].expirationTerm = moment(val[i].expirationTerm).format(
          "YYYY-MM-DD"
        );
      }
      this.$emit("selectData", val);
      this.$emit("shut");
    },
    // 添加方法
    passageAdd(value) {
      let selectList = this.selectList;
      let id = value.id;
      const length = this.selectList.length;
      if (length < 5) {
        let result = selectList.findIndex((row) => row.id === id);
        console.log(result);
        if (result == -1) {
          value.activationTime = moment();
          value.expirationTerm = moment("2099-12-31");
          selectList.push(value);
        } else {
          this.$warning({
            title: this.$t("information.a224"),
            centered: true,
            content: this.$t("information.a225"),
          });
        }
      } else {
        this.$warning({
          title: this.$t("information.a226"),
          centered: true,
          content: this.$t("information.a227"),
        });
      }
    },
    // 删除方法
    passageDelete(value) {
      let id = value.id;
      let selectList = this.selectList;
      let result = selectList.findIndex((row) => row.id === id);
      console.log(result);
      selectList.splice(result, 1);
    },
  },
};
</script>

<style scoped>
div /deep/.ant-modal-body {
  padding: 0px;
}
.pop_main {
  height: 500px;
  padding: 20px;
  overflow: auto;
}
</style>