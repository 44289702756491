<!--人员管理--人脸设置-->
<!--图片的裁剪区 add by pzj -->
<template>
  <div id="faceSet" ref="faceSet">
    <a-modal
      :title="$t('information.a204')"
      centered
      :width="970"
      :visible="visible"
      :getContainer="() => this.$refs.faceSet"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a190") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="imgSet"
          :disabled="submitEnable"
        >
          {{ $t("information.a191") }}
        </a-button>
      </template>
      <div style="display:flex;justify-content:space-between;">
          <div class="face-demo">
              <h3>{{ $t("information.a297")}}</h3>
              <img v-if="imgsrc=='zhcn'" src="../../../../public/images/face/FaceDemo.jpg"/>
              <img v-else-if="imgsrc=='enus'" src="../../../../public/images/face/FaceDemoUS.jpg"/>
              <img v-else-if="imgsrc=='zhtw'" src="../../../../public/images/face/FaceDemo.jpg"/>
              <img v-else="imgsrc=='jajp'" src="../../../../public/images/face/FaceDemoJP.jpg"/>
              <div style="padding-top: 40px;font-size:13px;font-weight: bold;color: red">
                 <span>{{ $t("information.a206") }}</span>
              </div>
          </div>
            <div>
                <div :style="{ height: '380px',width: '410px' }">
                    <h3>{{ $t("information.a296") }}</h3>
                    <vue-cropper
                            ref="cropper"
                            :img="options.img"
                            :info="true"
                            :autoCrop="options.autoCrop"
                            :autoCropWidth="options.autoCropWidth"
                            :autoCropHeight="options.autoCropHeight"
                            :fixedBox="options.fixedBox"
                            :outputSize="options.size"
                            :outputType="options.outputType"
                    >
                    </vue-cropper>
                </div>
                <br>
                <br>
                <a-row style="display:flex;justify-content:space-between;">
                    <a-col :lg="6" :md="6">
                        <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false">
                            <a-button type="primary" icon="upload">{{ $t("information.a295") }}</a-button>
                        </a-upload>
                    </a-col>
                    <a-col :lg="{span: 1, offset: 3}" :md="2">
                        <a-button icon="plus" shape="circle" @click="changeScale(1)"/>
                    </a-col>
                    <a-col :lg="{span: 1, offset: 1}" :md="2">
                        <a-button icon="minus" shape="circle" @click="changeScale(-1)"/>
                    </a-col>
                    <a-col :lg="{span: 1, offset: 1}" :md="2">
                        <a-button icon="undo" shape="circle" @click="rotateLeft"/>
                    </a-col>
                    <a-col :lg="{span: 1, offset: 1}" :md="2">
                        <a-button icon="redo" shape="circle" @click="rotateRight"/>
                    </a-col>
                </a-row>
            </div>
        </div>
    </a-modal>
  </div>
</template>

<script>
import VueCropper from 'vue-cropper'
export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
        options: {
            img:"",
            autoCrop: true,
            autoCropWidth: 240,
            autoCropHeight: 320,
            fixedBox: true,
            size:0.8,
            outputType:"jpeg"
        },
        imgsrc:this.$i18n.locale,
        submitEnable:true,
    };
  },
  methods: {
    Cancel() {
      this.$emit("shut");
      this.options.img ="";
    },
    imgSet() {
        //获取裁剪的图片 add by pzj
        this.$refs.cropper.getCropData((data) => {
            this.$emit("dataChange",data);
            this.Cancel();
        })
    },
    beforeUpload(file) {
      console.log(file);
      //只识别 jpg格式的图片  add by pzj  20230707 file.type === "image/png"
      const isJpgOrPng =
        file.type === "image/jpeg" ;
      const size = Math.ceil(file.size / 1024);
      console.log(size);
      if (!isJpgOrPng) {
        this.$message.error(this.$t("information.a255"));
        //图片大小不能超过128K add by pzj
      } else if (size > 6*1024) {
        this.$message.error(this.$t("information.a254"));
      } else {
        let reader = new FileReader();
        reader.onload = (evt) => {
          // this.imageUrl = evt.target.result;
          this.options.img =evt.target.result;
          this.submitEnable=false;
        };
        reader.readAsDataURL(file);
      }
      return false;
    },
    //图片的大小缩放
    changeScale (num) {
          num = num || 1
          this.$refs.cropper.changeScale(num)
      },
    //图片逆时针旋转
    rotateLeft () {
        this.$refs.cropper.rotateLeft()
    },
      //图片顺时针旋转
    rotateRight () {
        this.$refs.cropper.rotateRight()
    },
  },
};
</script>

<style scoped>
div /deep/.ant-upload-picture-card-wrapper {
  display: flex;
  justify-content: center;
}
div /deep/.avatar-uploader > .ant-upload {
  width: 250px;
  height: 250px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.face-demo {
    width: 500px;
    height: 320px;
}
.face-demo img {
    width: 100%;
    height: 100%;
}

</style>