<!--人员管理--管理所属编辑和选择-->
<template>
  <div id="personList" ref="personList">
    <a-modal
      :title="$t('information.a168')"
      :visible="visible"
      :getContainer="() => this.$refs.personList"
      @cancel="shut"
      @ok="handleOk"
      :ok-button-props="disabled_ok_button"
      :destroyOnClose="true"
      v-drag
    >
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <div class="tree_vessel">
          <a-tree
            showLine
            :tree-data="data"
            :replace-fields="replaceFields"
            @select="nodeSelect"
          />
        </div>
        <div class="button">
          <a-button
            type="primary"
            style="width: 120px"
            :disabled="disabled_add_button"
            @click="addCard"
          >
            {{ $t("information.a35") }}
          </a-button>
          <a-button
            type="primary"
            style="width: 120px"
            :disabled="disabled_alteration_button"
            @click="updateCard"
          >
            {{ $t("information.a50") }}
          </a-button>
          <a-button
            type="primary"
            style="width: 120px"
            :disabled="disabled_remove_button"
            @click="deleteCard"
          >
            {{ $t("information.a31") }}
          </a-button>
        </div>
      </div>
    </a-modal>
    <!-- 卡所属添加 -->
    <a-modal
      :title="$t('information.a52')"
      :width="350"
      centered
      :visible="add_visible"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.personList"
      @ok="addOk"
      @cancel="addCancel"
    >
      <div class="title"></div>
      <span>{{ $t("information.a53") }}</span>
      <a-input
        v-model="addName"
        :placeholder="$t('information.a54')"
        style="width: 180px"
      />
    </a-modal>
    <!-- 卡所属名称变更 -->
    <a-modal
      :title="$t('information.a55')"
      :width="350"
      centered
      :visible="update_visible"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.personList"
      @ok="updateOk"
      @cancel="updateCancel"
    >
      <div class="title"></div>
      <span>{{ $t("information.a53") }}</span>
      <a-input
        v-model="parentname"
        allow-clear
        :placeholder="$t('information.a54')"
        style="width: 180px; margin-left: 15px"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  addPersonGr,
  updatePersonGr,
  deletePersonGr,
  getPersonList,
} from "../../../api/info";

export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      disabled_add_button: true,
      disabled_alteration_button: true,
      disabled_remove_button: true,
      confirmLoading: false,
      add_visible: false,
      update_visible: false,
      parentname: "",
      parentnameTitle: "",
      id: "",
      addName: "",
      siteGrId: undefined,
      siteId: "",
      select: {},
      disabled_ok_button: { props: { disabled: true } },
      replaceFields: { title: "name", children: "children", key: "id" },
      selectvalue: null,
    };
  },
  watch: {
    // 监听建筑群id调用管理所属获取的方法
    "$store.getters.sitegrId": {
      handler: function (newValue, oldValue) {
        if (newValue !== "") {
          this.getList();
          this.disabled_add_button = true;
          this.disabled_alteration_button = true;
          this.disabled_remove_button = true;
          this.disabled_ok_button.props.disabled = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 管理所属列表选中确定按钮
    handleOk() {
      this.selectvalue = null;
      const data = this.select;
      this.joint(data);
      this.selectvalue.id = this.selectvalue.id.split("_")[0];
      console.log("selectvalue", this.selectvalue);
      this.$emit("change", this.selectvalue);
      this.shut();
    },
    // 树选择后拼接选择数据所有上级
    joint(value) {
      const { dataRef } = value;
      const _obj = JSON.stringify(dataRef);
      const obj = JSON.parse(_obj);
      obj.children = this.selectvalue;
      this.selectvalue = obj;
      if (value.vcTreeNode.dataRef !== undefined) {
        this.selectvalue = [this.selectvalue];
        this.joint(value.vcTreeNode);
      }
    },
    // 获取管理所属列表
    getList() {
      this.loading = true;
      const data = {
        siteGrId: this.$store.getters.sitegrId,
      };
      getPersonList(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          this.data = data;
          // console.log(res);
        })
        .catch((err) => {
          this.loading = false;
          // console.log(err);
        });
    },
    // 卡片所属编辑关闭函数
    shut() {
      this.$emit("shut", false);
    },
    // 添加按钮
    addCard() {
      this.add_visible = true;
    },
    // 添加卡所属确定按钮
    addOk() {
      this.add_visible = false;
      this.loading = true;
      let body = {
        name: this.addName,
        id: this.id,
      };
      let query = {
        siteId: this.siteId,
      };
      console.log(body, query);
      addPersonGr(body, query)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          this.addName = "";
          let { errorCode } = res;
          // console.log(res)
          if (errorCode == "00") {
            this.$message.success(res.msg);
            this.getList();
          } else if (errorCode !== "00") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 添加卡所属取消按钮
    addCancel() {
      this.add_visible = false;
    },
    // 更新按钮
    updateCard() {
      this.update_visible = true;
    },
    // 更新卡所属确定按钮
    updateOk() {
      let body = {
        name: this.parentname,
        id: this.id,
      };
      let query = {
        siteId: this.siteId,
      };
      this.update_visible = false;
      this.loading = true;
      updatePersonGr(body, query)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          let { errorCode } = res;
          if (errorCode == "00") {
            this.parentnameTitle = this.parentname;
            this.$message.success(res.msg);
            this.getList();
          } else if (errorCode !== "00") {
            this.$message.error(res.msg);
            this.parentname = this.parentnameTitle;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 更新卡所属取消按钮
    updateCancel() {
      this.update_visible = false;
      this.parentname = this.parentnameTitle;
    },
    // 删除按钮
    deleteCard() {
      this.$confirm({
        content: `${this.$t("information.a56")}${this.parentname}${this.$t(
          "information.a57"
        )}`,
        centered: true,
        onOk: () => {
          let body = {
            name: this.parentname,
            id: this.id,
          };
          let query = {
            siteId: this.siteId,
          };
          this.loading = true;
          deletePersonGr(body, query)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              let { errorCode } = res;
              if (errorCode == "00") {
                this.$message.success(res.msg);
                this.getList();
              } else if (errorCode !== "00") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        },
      });
    },
    // 树形选择回调
    nodeSelect(selectedKeys, info) {
      // console.log(selectedKeys);
      // console.log(info.node);
      let now = info.node.dataRef;
      let { hierarchyDepth } = now;
      let data = info.node.vcTreeNode;
      let parentname = now.name;
      let id = now.id;
      this.parentname = parentname;
      this.parentnameTitle = parentname;
      this.id = id;
      this.select = info.node;
      if (hierarchyDepth !== 0) {
        for (let i = 1; i < hierarchyDepth; i++) {
          data = data.vcTreeNode;
        }
        this.siteId = data.dataRef.id.split("_")[0];
      }
      if (hierarchyDepth == 0) {
        this.disabled_add_button = true;
        this.disabled_alteration_button = true;
        this.disabled_remove_button = true;
        this.disabled_ok_button.props.disabled = true;
      } else if (hierarchyDepth == 1) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = true;
        this.disabled_remove_button = true;
        this.disabled_ok_button.props.disabled = false;
      } else if (hierarchyDepth == 2) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_ok_button.props.disabled = false;
      } else if (hierarchyDepth == 3) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_ok_button.props.disabled = false;
      } else if (hierarchyDepth == 4) {
        this.disabled_add_button = false;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_ok_button.props.disabled = false;
      } else if (hierarchyDepth == 5) {
        this.disabled_add_button = true;
        this.disabled_alteration_button = false;
        this.disabled_remove_button = false;
        this.disabled_ok_button.props.disabled = false;
      }
    },
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.tree_vessel {
  height: 250px;
  overflow: auto;
  border: 1px solid rgba(12, 12, 12, 0.2);
  margin-bottom: 15px;
}
.button {
  display: flex;
  justify-content: space-between;
}
.input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.shut {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid rgba(12, 12, 12, 0.2);
}
.title {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
}
div /deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
}
</style>