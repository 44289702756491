<!--认证信息组模块--人员管理-->
<template>
  <div id="peopleManagement" v-loading="loading" :element-loading-text="$t('information.a1')" ref="peopleManagement">
    <!-- 1 -->
    <!-- 头部 -->
    <div class="header">
      <my-headertitle :quit="true">{{ $t("information.a111") }}</my-headertitle>
    </div>
    <!-- 2 -->
    <!-- 主内容区域 -->
    <div class="main">
      <!-- 基本信息 -->
      <div class="outerframe">
        <div class="outerframe-title">{{ $t("information.a113") }}</div>
        <div class="outerframe-container">
          <a-form-model layout="inline" :model="personBean" ref="ruleForm" :rules="rules">
            <div class="basicinfo-form">
              <!-- 管理代码 -->
              <a-form-model-item :label="$t('information.a7')" prop="managementCode">
                <a-input style="width: 150px" v-model="personBean.managementCode" :placeholder="$t('information.a8')" :maxLength="30" allow-clear v-Input-NumberText/>
              </a-form-model-item>
              <!-- 姓名 -->
              <a-form-model-item :label="$t('information.a5')" prop="name">
                <a-input style="width: 150px" v-model="personBean.name" :placeholder="$t('information.a6')" :maxLength="30" allow-clear v-InputText />
              </a-form-model-item>
              <!-- 部门 -->
              <a-form-model-item :label="$t('information.a67')">
                <a-input style="width: 150px" v-model="personBean.kana" :placeholder="$t('information.a68')" :maxLength="30" allow-clear v-InputText />
              </a-form-model-item>
             </div>
            <div class="basicinfo-form">
              <!-- 手机号 -->
              <a-form-model-item :label="  $t('information.a114')" prop="mobilePhone">
                <a-input style="width: 150px" v-model="personBean.mobilePhone" :placeholder="$t('information.a116')" :maxLength="11" allow-clear v-pureNumber/>
              </a-form-model-item>
              <!-- 国籍 -->
              <a-form-model-item :label="$t('information.a256')">
                <a-select v-model="personBean.nationalityCode" style="width: 150px" @change="nationalityCodeChange" :placeholder="$t('information.a256')">
                  <a-select-option v-for="(itme, index) in nationalityList" :key="index" :value="itme.no"> {{ itme.text }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
          <div class="basicinfo-site">
            <div>
              <!-- 所属 -->
              <div class="basicinfo-site-row">
                <span>{{ $t("information.a117") }}</span>
                <span>{{ genusname }}</span>
              </div>
              <!-- 所属分组 -->
              <div class="basicinfo-site-row">
                <span>{{ $t("information.a118") }}</span>
                <span>{{ personGrBean.name }}</span>
              </div>
              <!-- 注册日期 -->
              <div class="basicinfo-site-row">
                <span>{{ $t("information.a119") }}</span>
                <span>{{ personBean.registrationTime }}</span>
              </div>
              <!-- 更新日期 -->
              <div>
                <span>{{ $t("information.a120") }}</span>
                <span>{{ personBean.updatedTime }}</span>
              </div>
            </div>
            <!-- 所属变更按钮 -->
            <a-button type="primary" @click="openalteration"> {{ $t("information.a121") }} </a-button>
          </div>
        </div>
      </div>
      <!-- 认证信息 -->
      <div class="outerframe" v-show="personGrBean.id !== undefined">
        <div class="outerframe-title" ref="cardSet"> {{ $t("information.a2") }} </div>
        <a-tabs v-model="cardKey">
          <a-tab-pane :key="index" :tab="`${$t('information.a122')}${index + 1}`" v-for="(item, index) in credentialCardInfoBeans">
            <!-- 认证信息--卡信息 -->
            <div class="cardinfo-outerframe">
              <!-- 卡信息--标题 -->
              <div class="cardinfo-title">
                <span>{{ $t("information.a123") }}</span>
                <a-button type="primary" ghost @click="deleteCradInfo" :disabled="formerCardId[cardKey] == undefined"> {{ $t("information.a124") }} </a-button>
              </div>
              <!-- 卡信息--普通卡 -->
              <div class="cardinfo-row">
                <div class="cardinfo-row-left">
                  <span> {{ $t("information.a127") }} </span>
                </div>
                <div class="cardinfo-row-right">
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a128") }}</span>
                    <span>{{ item.credentialCardBean.cardFaceNo | none }}</span>
                    <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '35px' }" @click="cardSelect"/>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a129") }}</span>
                    <span>{{ item.credentialCardBean | time }}</span>
                    <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '35px' }" v-show="item.credentialCardBean.cardNo !== undefined" @click="openCardTimeSet"/>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a130") }}</span>
                    <a-radio-group v-model="item.credentialCardBean.status" name="radioGroup">
                      <a-radio :value="1"> {{ $t("information.a131") }} </a-radio>
                      <a-radio :value="2"> {{ $t("information.a132") }} </a-radio>
                      <a-radio :value="3"> {{ $t("information.a133") }} </a-radio>
                    </a-radio-group>
                  </div>
                </div>
              </div>
              <!-- 卡信息--指静脉 -->
              <div class="cardinfo-row">
                <div class="cardinfo-row-left">
                  <span> {{ $t("information.a134") }} </span>
                </div>
                <div class="cardinfo-row-right">
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a135") }}</span>
                    <span>{{ oneFinger }}</span>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a136") }}</span>
                    <span>{{ twoFinger }}</span>
                    <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '35px' }" @click="openFinger" v-show="item.credentialCardBean.cardNo !== undefined"/>
                    <a-icon type="delete" :style="{ fontSize: '20px', 'margin-left': '10px', color: '#f50' }" v-show="fingerDelete" @click="fingerIfonDelete"/>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a129") }}</span>
                    <span>{{ item.fingerVeinBean | time }}</span>
                    <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '35px' }" v-show="fingerDelete" @click="openFingerTimeSet"/>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a137") }}</span>
                    <div>
                      <a-select v-model="item.fingerVeinBean.threshold" style="width: 80px" :getPopupContainer="oned" >
                        <a-select-option :value="1"> 1 </a-select-option>
                        <a-select-option :value="2"> 2 </a-select-option>
                        <a-select-option :value="3"> 3 </a-select-option>
                        <a-select-option :value="4"> 4 </a-select-option>
                        <a-select-option :value="5"> 5 </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 卡信息--人脸 -->
              <div class="cardinfo-row">
                <div class="cardinfo-row-left">
                  <span> {{ $t("information.a138") }} </span>
                </div>
                <div class="cardinfo-row-right">
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a139") }}</span>
                    <a-icon type="picture" v-if="item.facePhotoBean.faceImage" :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '10px' }" @click="openBreviary"/>
                    <span v-else>----</span>
                    <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '35px' }" v-show="item.credentialCardBean.cardNo !== undefined" @click="openFaceSet"/>
                    <a-icon type="delete" :style="{ fontSize: '20px', 'margin-left': '10px', color: '#f50' }" v-show="item.facePhotoBean.faceImage" @click="faceDataDelete"/>
                  </div>
                </div>
              </div>
              <!-- 卡信息--特权设置 -->
              <div class="cardinfo-row">
                <div class="cardinfo-row-left">
                  <span> {{ $t("information.a140") }} </span>
                </div>
                <div class="cardinfo-row-right">
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a141") }}</span>
                    <a-radio-group name="radioGroup" v-model="item.credentialCardBean.cleanerCard" >
                      <a-radio :value="0"> {{ $t("information.a142") }} </a-radio>
                      <a-radio :value="1"> {{ $t("information.a143") }} </a-radio>
                    </a-radio-group>
                    <span style="margin-left: 50px"> {{ $t("information.a144") }} </span>
                    <a-radio-group name="radioGroup" v-model="item.credentialCardBean.fingerVein" >
                      <a-radio :value="0"> {{ $t("information.a142") }} </a-radio>
                      <a-radio :value="1"> {{ $t("information.a143") }} </a-radio>
                    </a-radio-group>
                  </div>
                </div>
              </div>
              <!-- 卡信息--消费设置 -->
              <div class="cardinfo-row">
                <div class="cardinfo-row-left">
                  <span> {{ $t("information.a145") }} </span>
                </div>
                <div class="cardinfo-row-right">
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a146") }}</span>
                    <div>
                      <a-select style="width: 120px" v-model="item.consumeCardInfoBean.consumeCardType" :getPopupContainer="oned">
                        <a-select-option v-for="(item, index) in consumeCardTypes" :key="index" :value="item.consumeCardType"> {{ item.consumeCardTypeName }} </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a147") }}</span>
                    <div>
                      <a-select v-model="item.consumeCardInfoBean.consumeType" style="width: 120px" :getPopupContainer="oned">
                        <a-select-option :value="1"> {{ $t("information.a78") }}</a-select-option >
                        <a-select-option :value="2"> {{ $t("information.a79") }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 卡信息--梯控 -->
              <div class="cardinfo-row" v-if="elevator">
                <div class="cardinfo-row-left">
                  <span> {{ $t("information.a148") }} </span>
                </div>
                <div class="cardinfo-row-right">
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a146") }}</span>
                    <div>
                      <a-select style="width: 120px" v-model="elevatorCardType" :getPopupContainer="oned" >
                        <a-select-option :value="0">普通</a-select-option>
                        <a-select-option :value="40">管理员</a-select-option>
                        <a-select-option :value="20">防范直驶</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="cardinfo-row-right-bulk">
                    <span>{{ $t("information.a149") }}</span>
                    <div>
                      <a-select style="width: 120px" v-model="rm_num" :getPopupContainer="oned">
                        <a-select-option v-for="(item, index) in room" :key="index" :value="item.rm_num"> {{ item.rm_ShowName }} </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 认证信息--关联站点 -->
            <div class="cardinfo-outerframe">
              <!-- 关联站点--标题 -->
              <div class="cardinfo-title">
                <span>{{ $t("information.a125") }}</span>
                <a-button style="width: 120px" type="primary" ghost @click="openSiteSelect(0)"> {{ $t("information.a126") }} </a-button>
              </div>
              <!-- 关联站点 -->
              <a-table :columns="columns" :dataSource="item.accessRoleListBean" bordered :scroll="{ x: 2500 }" :pagination="false" :rowKey="(record) => record.siteId">
                <template slot="chaozuo" slot-scope="text, record">
                  <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE' }" @click="openPassage(record)"/>
                  <a-divider type="vertical" />
                  <a-icon type="delete" :style="{ fontSize: '20px', color: '#f50' }" @click="siteDeleterow(record)"/>
                </template>
                <template slot="accessRoleList[0].name" slot-scope="text, record">
                  <a href="javascript:;" @click="openTrafficMode(record,0)">{{text}}</a>
                </template>
                <template slot="accessRoleList[1].name" slot-scope="text, record">
                  <a href="javascript:;" @click="openTrafficMode(record,1)">{{text}}</a>
                </template>
                <template slot="accessRoleList[2].name" slot-scope="text, record">
                  <a href="javascript:;" @click="openTrafficMode(record,2)">{{text}}</a>
                </template>
                <template slot="accessRoleList[3].name" slot-scope="text, record">
                  <a href="javascript:;" @click="openTrafficMode(record,3)">{{text}}</a>
                </template>
                <template slot="accessRoleList[4].name" slot-scope="text, record">
                  <a href="javascript:;" @click="openTrafficMode(record,4)">{{text}}</a>
                </template>
              </a-table>
            </div>
            <!-- 认证信息--关联电梯 -->
            <div class="cardinfo-outerframe" v-if="elevator">
              <!-- 关联电梯--标题 -->
              <div class="cardinfo-title">
                <span>{{ $t("information.a150") }}</span>
                <a-button style="width: 120px" type="primary" ghost @click="openSiteSelect(12)"> {{ $t("information.a151") }} </a-button>
              </div>
              <!-- 关联电梯 -->
              <a-table :columns="elevatorcolumns" :dataSource="item.elevatorAccessRoleListBean" bordered :scroll="{ x: 2500 }" :pagination="false" :rowKey="(record) => record.siteId" >
                <template slot="chaozuo" slot-scope="text, record">
                  <a-icon type="form" :style="{ fontSize: '20px', color: '#7682CE' }" @click="openelevator(record)"/>
                  <a-divider type="vertical" />
                  <a-icon type="delete" :style="{ fontSize: '20px', color: '#f50' }" @click="elevatorDeleterow(record)"/>
                </template>
              </a-table>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <!-- 3 -->
    <!-- 页底 -->
    <div class="footer">
      <a-button type="primary" style="width: 120px" @click="personSetUp"> {{ $t("information.a65") }} </a-button>
    </div>

    <!-- 所属变更弹窗 -->
    <my-personlist :visible="personVisible" @shut="handleCancel" @change="selectData" v-if="personDestroy"/>

    <!-- 普通卡选择弹框 -->
    <my-cardSelect :siteId="personGrBean.id" :visible="cardSelectVisible" @shut="cardSelectCancel" @selectData="cardSelectData"/>

    <!-- 普通卡有效期限设置 -->
    <my-timeset :visible="timeSetVisible" @shut="timeSetCancel" :data="credentialCardInfoBeans[cardKey]" :value="1" @dataChange="timeSetSelectData"/>

    <!--指静脉信息获取弹窗 -->
    <my-finger :visible="fingerVisible" @shut="fingerCancel" @dataChange="fingerData"/>

    <!-- 指静脉有效期限设置 -->
    <my-timeset :visible="fingerTimeVisible" :data="credentialCardInfoBeans[cardKey]" :value="2" @shut="fingerTimeSetCancel" @dataChange="fingerTimeSetSelectData"/>

    <!-- 人脸图片选择设置 -->
    <my-faceset :visible="faceVisible" @shut="faceCancel" @dataChange="faceSelectData"/>

    <!-- 人脸缩略图 -->
    <a-modal :visible="previewVisible" :footer="null" :width="250" centered @cancel="breviaryCancel">
      <img class="img" :src="'data:image/jpeg;base64,' + credentialCardInfoBeans[cardKey].facePhotoBean.faceImage"/>
    </a-modal>

    <!-- 关联站点/电梯选择弹窗 -->
    <my-siteSelect :siteId="personGrBean.id" :visible="siteSelectVisible" :siteList="siteList" :elevatorList="elevatorList" :Type="Type" :personGrId="personGrId" @shut="siteSelectCancel" @selectData="siteSelectData" v-if="siteSelectVisible"/>

    <!-- 通行模式选择弹窗 -->
    <my-passage :data="passageData" :visible="passageVisible" :siteList="siteList" @shut="passageCancel" @selectData="passageDate" v-if="passageVisible" />

    <!-- 电梯模式选择弹窗 -->
    <my-elevator :siteId="personGrBean.id" :data="elevatorData" :visible="elevatorVisible" :elevatorList="elevatorList" @shut="elevatorCancel" @elevatorDate="elevatorDate" v-if="elevatorVisible" />

    <!-- 设备通信检查的提示框 -->
    <my-peopleManagementDevice :visible="deviceVisible" :deviceValueList="deviceValueList" @shut="deviceValueCancel" @goHander="setUpDeviceValueFlg" v-if="deviceVisible"/>

    <!-- 人脸发送失败的提示框 -->
    <my-faceErrorMessage :visible="faceErrorMessageVisible" :faceErrorTitle="faceErrorTitle" :faceErrorData="faceErrorMessageList" @shut="faceErrorMessageCancel" v-if="faceErrorMessageVisible"/>

    <!-- 通行模式详情弹窗 -->
    <my-trafficMode :visible="trafficModeVisible" :data="trafficModeData" :value="trafficModeValue" @shut="trafficModeCancel"/>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import personList from "./components/personList";
import timeSet from "./components/timeSet";
import fingerSelect from "./components/fingerSelect";
import faceSet from "./components/faceSet";
import cardSelect from "./components/cardSelect";
import siteSelect from "./components/siteSelect";
import PassageModeSelection from "./components/PassageModeSelection";
import elevatorSelect from "./components/elevatorSelect";
import trafficMode from './components/trafficMode';
import peopleManagementDevice from './components/peopleManagementDevice';
import faceErrorMessage from './components/faceErrorMessage';
import { getPersonInfoManage, personSetUp, getroomList, getCardTypeList, getNationalities, getSiteDeviceStatus } from "../../api/info";
import moment from "moment";

export default {
  name: "peopleManagement",
  components: {
    "my-headertitle": headerTitle,
    "my-personlist": personList,
    "my-cardSelect": cardSelect,
    "my-siteSelect": siteSelect,
    "my-passage": PassageModeSelection,
    "my-elevator": elevatorSelect,
    "my-timeset": timeSet,
    "my-finger": fingerSelect,
    "my-faceset": faceSet,
    "my-trafficMode": trafficMode,
    "my-peopleManagementDevice":peopleManagementDevice,
    "my-faceErrorMessage":  faceErrorMessage,
  },
  data() {
    return {
      loading: false,
      nationalityList: [],
      deviceValueList: [],
      deviceVisible: false,
      deviceFlg: 0,

      faceErrorMessageList: [],
      faceErrorMessageVisible: false,
      faceErrorTitle: "",

      personBean: {
        managementCode: "",
        name: "",
        kana: "",
        registrationTime: "",
        updatedTime: "",
        mobilePhone: "",
        nationalityCode: "",
        nationalityName: "",
      },
      personGrBean: {
        contractTypes: [],
        name: "",
        id: undefined,
      },
      rules: {
        managementCode: [
          {
            required: true,
            message: () => {
              return this.$t("information.a260");
            },
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: () => {
              return this.$t("information.a261");
            },
            trigger: "change",
          },
        ],
         mobilePhone: [{ validator: this.validateMobile, trigger: "blur" }],
      },
      personVisible: false,
      personDestroy: true,
      cardKey: 0,
      formerCardId: [undefined, undefined, undefined],
      credentialCardInfoBeans: [
        {
          credentialCardBean: {},
          accessRoleListBean: [],
          elevatorAccessRoleListBean: [],
          fingerVeinBean: {},
          facePhotoBean: {},
          consumeCardInfoBean: {},
        },
        {
          credentialCardBean: {},
          accessRoleListBean: [],
          elevatorAccessRoleListBean: [],
          fingerVeinBean: {},
          facePhotoBean: {},
          consumeCardInfoBean: {},
        },
        {
          credentialCardBean: {},
          accessRoleListBean: [],
          elevatorAccessRoleListBean: [],
          fingerVeinBean: {},
          facePhotoBean: {},
          consumeCardInfoBean: {},
        },
      ],
      timeSetVisible: false,
      fingerVisible: false,
      fingerTimeVisible: false,
      previewVisible: false,
      faceVisible: false,
      consumeCardTypes: [],
      cardSelectVisible: false,
      siteSelectVisible: false,
      Type: 0,
      passageVisible: false,
      elevatorVisible: false,
      trafficModeVisible:false,
      trafficModeData:{},
      trafficModeValue:0,
      passageData: {},
      elevatorData: {},
      arr: [],
      room: [],
      rm_num: "",
      elevatorCardType:'',
      elevator: true,
      elevatorcolumns: [
        {
          title: "NO",
          customRender: (text, record, index) => `${index + 1}`,
          width: 60,
          align: "center",
          fixed: "left",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a160");
            } else {
              return this.parent.$t("information.a160");
            }
          },
          dataIndex: "siteName",
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a153");
            } else {
              return this.parent.$t("information.a153");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a161");
                } else {
                  return this.parent.$t("information.a161");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 0) {
                  return record.elevatorRoomBeanList[0].rm_floor;
                }
              },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a162");
                } else {
                  return this.parent.$t("information.a162");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 0) {
                  return record.elevatorRoomBeanList[0].rm_ShowName;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a156");
            } else {
              return this.parent.$t("information.a156");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a161");
                } else {
                  return this.parent.$t("information.a161");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 1) {
                  return record.elevatorRoomBeanList[1].rm_floor;
                }
              },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a162");
                } else {
                  return this.parent.$t("information.a162");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 1) {
                  return record.elevatorRoomBeanList[1].rm_ShowName;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a157");
            } else {
              return this.parent.$t("information.a157");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a161");
                } else {
                  return this.parent.$t("information.a161");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 2) {
                  return record.elevatorRoomBeanList[2].rm_floor;
                }
              },
              ellipsis: true,
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a162");
                } else {
                  return this.parent.$t("information.a162");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 2) {
                  return record.elevatorRoomBeanList[2].rm_ShowName;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a158");
            } else {
              return this.parent.$t("information.a158");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a161");
                } else {
                  return this.parent.$t("information.a161");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 3) {
                  return record.elevatorRoomBeanList[3].rm_floor;
                }
              },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a162");
                } else {
                  return this.parent.$t("information.a162");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 3) {
                  return record.elevatorRoomBeanList[3].rm_ShowName;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a159");
            } else {
              return this.parent.$t("information.a159");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a161");
                } else {
                  return this.parent.$t("information.a161");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 4) {
                  return record.elevatorRoomBeanList[4].rm_floor;
                }
              },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a162");
                } else {
                  return this.parent.$t("information.a162");
                }
              },
              customRender: (text, record, index) => {
                let length = record.elevatorRoomBeanList.length;
                if (length > 4) {
                  return record.elevatorRoomBeanList[4].rm_ShowName;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a48");
            } else {
              return this.parent.$t("information.a48");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          align: "center",
          fixed: "right",
        },
      ],
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) => `${index + 1}`,
          width: 60,
          align: "center",
          fixed: "left",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a152");
            } else {
              return this.parent.$t("information.a152");
            }
          },
          dataIndex: "siteName",
          align: "center",
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a153");
            } else {
              return this.parent.$t("information.a153");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a154");
                } else {
                  return this.parent.$t("information.a154");
                }
              },
              dataIndex: "accessRoleList[0].name",
              scopedSlots: { customRender: 'accessRoleList[0].name' },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a155");
                } else {
                  return this.parent.$t("information.a155");
                }
              },
              customRender: (text, record, index) => {
                let length = record.accessRoleList.length;
                if (length > 0) {
                  return `${record.accessRoleList[0].activationTime}~${record.accessRoleList[0].expirationTerm}`
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a156");
            } else {
              return this.parent.$t("information.a156");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a154");
                } else {
                  return this.parent.$t("information.a154");
                }
              },
              dataIndex: "accessRoleList[1].name",
              scopedSlots: { customRender: 'accessRoleList[1].name' },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a155");
                } else {
                  return this.parent.$t("information.a155");
                }
              },
              customRender: (text, record, index) => {
                let length = record.accessRoleList.length;
                if (length > 1) {
                  return `${record.accessRoleList[1].activationTime}~${record.accessRoleList[1].expirationTerm}`;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a157");
            } else {
              return this.parent.$t("information.a157");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a154");
                } else {
                  return this.parent.$t("information.a154");
                }
              },
              dataIndex: "accessRoleList[2].name",
              scopedSlots: { customRender: 'accessRoleList[2].name' },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a155");
                } else {
                  return this.parent.$t("information.a155");
                }
              },
              customRender: (text, record, index) => {
                let length = record.accessRoleList.length;
                if (length > 2) {
                  return `${record.accessRoleList[2].activationTime}~${record.accessRoleList[2].expirationTerm}`;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a158");
            } else {
              return this.parent.$t("information.a158");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a154");
                } else {
                  return this.parent.$t("information.a154");
                }
              },
              dataIndex: "accessRoleList[3].name",
              scopedSlots: { customRender: 'accessRoleList[3].name' },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a155");
                } else {
                  return this.parent.$t("information.a155");
                }
              },
              customRender: (text, record, index) => {
                let length = record.accessRoleList.length;
                if (length > 3) {
                  return `${record.accessRoleList[3].activationTime}~${record.accessRoleList[3].expirationTerm}`;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a159");
            } else {
              return this.parent.$t("information.a159");
            }
          },
          align: "center",
          children: [
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a154");
                } else {
                  return this.parent.$t("information.a154");
                }
              },
              dataIndex: "accessRoleList[4].name",
              scopedSlots: { customRender: 'accessRoleList[4].name' },
              align: "center",
            },
            {
              title: () => {
                const value = this.i18n;
                if (value === undefined) {
                  return this.$t("information.a155");
                } else {
                  return this.parent.$t("information.a155");
                }
              },
              customRender: (text, record, index) => {
                let length = record.accessRoleList.length;
                if (length > 4) {
                  return `${record.accessRoleList[4].activationTime}~${record.accessRoleList[4].expirationTerm}`;
                }
              },
              align: "center",
            },
          ],
        },
        {
          title: () => {
            const value = this.i18n;
            if (value === undefined) {
              return this.$t("information.a48");
            } else {
              return this.parent.$t("information.a48");
            }
          },
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          align: "center",
          fixed: "right",
        },
      ],
      personGrId:''
    };
  },
  watch: {
    // 获取建筑群Id请求管理所属列表，同时判断编辑页面和添加页面
    "$store.getters.sitegrId": {
      handler(newValue, oldValue) {
        this.personDestroy = false;
        this.loading = true;
        const id = this.$route.query.siteGrId;
        if (id !== undefined) {
          this.getData();
        } else {
          Object.assign(this.$data, this.$options.data());
          this.loading = false;
          this.personDestroy = true;
        }
        this.getNationalityInfo();
        // this.loading = false;
      },
      deep: true,
      immediate: true,
    },
    // 房间号和卡类型列表获取
    personGrBean: {
      handler(newValue) {
        // console.log("chishizhi", newValue);
        const contractTypes = newValue.contractTypes;
        const value = contractTypes.findIndex((row) => row == 12);
        // console.log(value);
        const data = {
          siteId: this.personGrBean.id,
        };
        if (newValue.id !== undefined) {
          getCardTypeList(data)
            .then((res) => {
              // console.log('消费设置--卡类型',res);
              const { data } = res;
              for (let i = 0; i < data.length; i++) {
                if (data[i].consumeCardTypeName === "-") {
                  data.splice(i, 1);
                  i = i - 1;
                }
              }
              this.consumeCardTypes = data;
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (value !== -1) {
          this.elevator = true;
          getroomList(data)
            .then((res) => {
              console.log(res);
              const { data } = res;
              this.room = data;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.elevator = false;
        }
      },
    },
  },
  filters: {
    none(value) {
      if (value == "" || value == null) {
        return "----";
      } else {
        return value;
      }
    },
    time(value) {
      let begin = value.activationTime;
      let end = value.expirationTerm;
      if (begin == null && end == null) {
        return "----";
      } else if (begin !== null && end == null) {
        return end;
      } else if (begin == null && end !== null) {
        return end;
      } else {
        return `${begin}~${end}`;
      }
    },
  },
  computed: {
    genusname() {
      const children = this.personGrBean.children;
      this.arr = [];
      if (children !== undefined) {
        this.recursion(children);
        return this.arr.join("/");
      } else {
        return "----";
      }
    },
    siteList() {
      const value = this.cardKey;
      switch (value) {
        case 0:
          return this.credentialCardInfoBeans[0].accessRoleListBean;
          break;
        case 1:
          return this.credentialCardInfoBeans[1].accessRoleListBean;
          break;
        case 2:
          return this.credentialCardInfoBeans[2].accessRoleListBean;
        default:
          return false;
      }
    },
    elevatorList() {
      const value = this.cardKey;
      switch (value) {
        case 0:
          return this.credentialCardInfoBeans[0].elevatorAccessRoleListBean;
          break;
        case 1:
          return this.credentialCardInfoBeans[1].elevatorAccessRoleListBean;
          break;
        case 2:
          return this.credentialCardInfoBeans[2].elevatorAccessRoleListBean;
        default:
          return false;
      }
    },
    fingerDelete() {
      let value =
        this.credentialCardInfoBeans[this.cardKey].fingerVeinBean.registerType;
      if (value == 0 || value == undefined) {
        return false;
      } else {
        return true;
      }
    },
    oneFinger() {
      const key = this.cardKey;
      const value =
        this.credentialCardInfoBeans[key].fingerVeinBean.registerType;
      if (value == undefined) {
        return "----";
      } else if (value == 0) {
        return "----";
      } else if (value == 1) {
        return this.$t("information.a164");
      } else if (value == 2) {
        return this.$t("information.a163");
      } else if (value == -1) {
        return this.$t("information.a165");
      } else {
        return this.$t("information.a164");
      }
    },
    twoFinger() {
      const key = this.cardKey;
      const value =
        this.credentialCardInfoBeans[key].fingerVeinBean.registerType;
      if (value == undefined) {
        return "----";
      } else if (value == 0) {
        return "----";
      } else if (value == 1) {
        return this.$t("information.a163");
      } else if (value == 2) {
        return this.$t("information.a164");
      } else if (value == -1) {
        return this.$t("information.a165");
      } else {
        return this.$t("information.a164");
      }
    },
  },
  methods: {
    moment,
    // 手机验证
    validateMobile (rule, value, callback) {
      if(!value){
        callback()
      }else{
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!reg.test(value)) {
          callback(this.$t("information.a262"));
        }else{
          callback()
        }
      }
    },
    nationalityCodeChange(e, option) {
      console.log("nationalityCodeChange : ", e, option);
      let nation = this.nationalityList[option.data.key];
      this.personBean.nationalityName = nation.text;
      console.log("nationalityCodeChange : ", this.personBean);
    },
    getNationalityInfo() {
      getNationalities()
        .then((res) => {
          if (res.errorCode == "00") {
            this.nationalityList = [...res.data];
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 页面数据获取函数
    getData() {
      const data = { personId: this.$route.query.id };
      getPersonInfoManage(data)
        .then((res) => {
          this.personDestroy = true;
          this.loading = false;
          console.log("人员管理页面的初始化数据", res);
          const {
            data: {
              personBean,
              personGrBean,
              credentialCardInfoBeans,
              roomNum,
              elevatorCardType
            },
          } = res;
          personGrBean.id = personGrBean.id.split("_")[0];
          this.personBean = personBean;
          this.personGrBean = personGrBean;
          this.credentialCardInfoBeans = credentialCardInfoBeans;
          this.rm_num = roomNum;
          this.elevatorCardType = elevatorCardType
          const length = credentialCardInfoBeans.length;
          if (length == 0) {
            for (let i = 0; i < 3; i++) {
              this.credentialCardInfoBeans.push({
                credentialCardBean: {},
                accessRoleListBean: [],
                elevatorAccessRoleListBean: [],
                fingerVeinBean: {},
                facePhotoBean: {},
                consumeCardInfoBean: {},
              });
            }
          } else if (length == 1) {
            for (let i = 0; i < 2; i++) {
              this.credentialCardInfoBeans.push({
                credentialCardBean: {},
                accessRoleListBean: [],
                elevatorAccessRoleListBean: [],
                fingerVeinBean: {},
                facePhotoBean: {},
                consumeCardInfoBean: {},
              });
            }
          } else if (length == 2) {
            for (let i = 0; i < 1; i++) {
              this.credentialCardInfoBeans.push({
                credentialCardBean: {},
                accessRoleListBean: [],
                elevatorAccessRoleListBean: [],
                fingerVeinBean: {},
                facePhotoBean: {},
                consumeCardInfoBean: {},
              });
            }
          }
          const formerCardId = this.formerCardId;
          for (let i = 0; i < length; i++) {
            formerCardId[i] =
              credentialCardInfoBeans[i].credentialCardBean.cardId;
          }
          this.formerCardId = formerCardId;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 开启管理所属选中列表
    openalteration() {
      this.personVisible = true;
    },
    // 关闭管理所属列表
    handleCancel() {
      this.personVisible = false;
    },
    // 管理所属列表选择数据获取
    selectData(e) {
      this.personGrBean = e;
    },
    // 删除卡信息
    deleteCradInfo() {
      const credentialCardBean = {};
      const accessRoleListBean = [];
      const elevatorAccessRoleListBean = [];
      const fingerVeinBean = {};
      const facePhotoBean = {};
      const consumeCardInfoBean = {};
      this.credentialCardInfoBeans[this.cardKey].credentialCardBean =
        credentialCardBean;
      this.credentialCardInfoBeans[this.cardKey].accessRoleListBean =
        accessRoleListBean;
      this.credentialCardInfoBeans[this.cardKey].elevatorAccessRoleListBean =
        elevatorAccessRoleListBean;
      this.credentialCardInfoBeans[this.cardKey].fingerVeinBean =
        fingerVeinBean;
      this.credentialCardInfoBeans[this.cardKey].facePhotoBean = facePhotoBean;
      this.credentialCardInfoBeans[this.cardKey].consumeCardInfoBean =
        consumeCardInfoBean;
    },
    // 打开未使用卡查询选择弹窗
    cardSelect() {
      this.cardSelectVisible = true;
    },
    // 关闭未使用卡查询弹窗
    cardSelectCancel() {
      this.cardSelectVisible = false;
    },
    // 未使用卡数据选择回调替换操作
    cardSelectData(value) {
      const index = this.cardKey;
      const formerCardId = this.formerCardId[index];
      if (!formerCardId) {
        value.fingerVein = 0
        value.cleanerCard = 0
        value.status = 1
        this.credentialCardInfoBeans[index].credentialCardBean = value
      } else {
        this.credentialCardInfoBeans[index].credentialCardBean = value
      }
    },
    // 打开普通卡有效期限设置弹窗
    openCardTimeSet() {
      this.timeSetVisible = true;
    },
    // 关闭普通卡有效期限设置弹窗
    timeSetCancel() {
      this.timeSetVisible = false;
    },
    // 普通卡有效期限设置
    timeSetSelectData(value) {
      const index = this.cardKey;
      this.credentialCardInfoBeans[index].credentialCardBean.activationTime =
        value.activationTime;
      this.credentialCardInfoBeans[index].credentialCardBean.expirationTerm =
        value.expirationTerm;
      this.timeSetVisible = false;
    },
    // 打开指纹信息获取弹框
    openFinger() {
      this.fingerVisible = true;
    },
    // 关闭指纹信息获取弹窗
    fingerCancel() {
      this.fingerVisible = false;
    },
    // 指纹信息设置
    fingerData(value) {
      const index = this.cardKey;
      this.credentialCardInfoBeans[index].fingerVeinBean.fingerVeinData1 =
        value.fingerVeinData1;
      this.credentialCardInfoBeans[index].fingerVeinBean.fingerVeinData2 =
        value.fingerVeinData2;
      this.credentialCardInfoBeans[index].fingerVeinBean.registerType =
        value.registerType;
      this.credentialCardInfoBeans[index].fingerVeinBean.threshold =
        value.threshold;
      this.credentialCardInfoBeans[index].fingerVeinBean.activationTime =
        moment().format("YYYY-MM-DD");
      this.credentialCardInfoBeans[index].fingerVeinBean.expirationTerm =
        "2099-12-31";
      this.credentialCardInfoBeans[index].fingerVeinBean.fingerOperType = 0;
    },
    // 指纹信息删除
    fingerIfonDelete() {
      const index = this.cardKey;
      this.credentialCardInfoBeans[index].fingerVeinBean = {};
      this.credentialCardInfoBeans[index].fingerVeinBean.fingerOperType = 1;
    },
    // 打开指纹信息有效期限设置弹窗
    openFingerTimeSet() {
      this.fingerTimeVisible = true;
    },
    // 关闭指纹信息有效期限设置弹窗
    fingerTimeSetCancel() {
      this.fingerTimeVisible = false;
    },
    // 指纹信息有效期限设置
    fingerTimeSetSelectData(value) {
      const index = this.cardKey;
      this.credentialCardInfoBeans[index].fingerVeinBean.activationTime =
        value.activationTime;
      this.credentialCardInfoBeans[index].fingerVeinBean.expirationTerm =
        value.expirationTerm;
      this.fingerTimeVisible = false;
    },
    // 打开人脸缩略图
    openBreviary() {
      this.previewVisible = true;
    },
    // 关闭人脸缩略图
    breviaryCancel() {
      this.previewVisible = false;
    },
    // 打开人脸设置弹窗
    openFaceSet() {
      this.faceVisible = true;
    },
    // 关闭人脸设置弹窗
    faceCancel() {
      this.faceVisible = false;
    },
    // 人脸数据获取设置
    faceSelectData(value) {
      const val = value.split(",")[1];
      const index = this.cardKey;
      this.credentialCardInfoBeans[index].facePhotoBean.faceImage = val;
      this.credentialCardInfoBeans[index].facePhotoBean.facePhotoOperType = 0;
    },
    // 人脸数据删除
    faceDataDelete() {
      const index = this.cardKey;
      this.credentialCardInfoBeans[index].facePhotoBean.faceImage = null;
      this.credentialCardInfoBeans[index].facePhotoBean.facePhotoOperType = 1;
    },
    // 下拉菜单节点获取挂载
    oned() {
      return this.$refs.cardSet;
    },
    // 打开站点选择弹窗
    openSiteSelect(value) {
      this.Type = value;
      this.siteSelectVisible = true;
    },
    // 关联站点选择弹窗关闭方法
    siteSelectCancel() {
      this.siteSelectVisible = false;
    },
    // 关联电梯和关联站点的选择数据设置
    siteSelectData(value) {
      // console.log(value);
      let key = this.Type;
      if (key == 12) {
        this.credentialCardInfoBeans[this.cardKey].elevatorAccessRoleListBean =
          value;
      } else {
        this.credentialCardInfoBeans[this.cardKey].accessRoleListBean = value;
      }
    },
    // 通行模式选择界面
    openPassage(value) {
      console.log(value);
      this.passageVisible = true;
      this.passageData = value;
    },
    // 通行模式选择数据确认
    passageDate(value) {
      this.passageData.accessRoleList = value;
    },
    // 关闭关联站点通行模式选择弹窗
    passageCancel() {
      this.passageVisible = false;
    },
    // 打开电梯通行模式选择弹窗
    openelevator(value) {
      this.elevatorVisible = true;
      this.elevatorData = value;
    },
    // 关闭电梯通行模式选择弹窗
    elevatorCancel() {
      this.elevatorVisible = false;
    },
    //关联电梯选择通行模式设置
    elevatorDate(value) {
      this.elevatorData.elevatorRoomBeanList = value;
    },
    // 删除关联站点已选择的站点
    siteDeleterow(value) {
      let list = this.credentialCardInfoBeans[this.cardKey].accessRoleListBean;
      let id = value.siteId;
      let result = list.findIndex((row) => row.siteId === id);
      list.splice(result, 1);
    },
    // 删除关联电梯已选择的站点
    elevatorDeleterow(value) {
      // console.log(value);
      let list =
        this.credentialCardInfoBeans[this.cardKey].elevatorAccessRoleListBean;
      let id = value.siteId;
      let result = list.findIndex((row) => row.siteId === id);
      list.splice(result, 1);
    },
    // 添加/编辑请求（发卡）
    personSetUp() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let rm_num = this.rm_num;
          let elevator = this.elevator;
          let seletc = this.personGrBean.id;
          if (seletc == undefined) {
            this.$error({
              title: this.$t("information.a166"),
              content: this.$t("information.a167"),
              centered: true,
            });
          } else if (rm_num == "" && elevator) {
            this.$error({
              title: this.$t("information.a252"),
              content: this.$t("information.a253"),
              centered: true,
            });
          } else {
            console.log('1')
            this.loading = true;
            const index = this.cardKey;
            const formerCardId = this.formerCardId[index];
            let cardId =
              this.credentialCardInfoBeans[index].credentialCardBean.cardId;
            // 判断当前操作状态，新增/换卡/删除给credentialCardInfoBeans字段添加对应的值
            // 1.新增，初始化缓存值和新值相同或初始化缓存值为undefined
            if (formerCardId == cardId || formerCardId == undefined) {
              this.credentialCardInfoBeans[index].cardOperType = 0;
            }
            // 2.删除，初始化缓存值不为undefined并且新值为undefinde
            else if (formerCardId !== undefined && cardId == undefined) {
              this.credentialCardInfoBeans[index].credentialCardBean.cardIds = [
                formerCardId,
              ];
              this.credentialCardInfoBeans[index].cardOperType = 1;
            }
            // 3.换卡，初始化缓存值和新值不同并且初始化缓存值不为undefined
            else if (formerCardId !== cardId && formerCardId !== undefined) {
              this.credentialCardInfoBeans[index].credentialCardBean.cardIds = [
                formerCardId,
              ];
              this.credentialCardInfoBeans[index].cardOperType = 2;
            }
            console.log(this.credentialCardInfoBeans[index]);
            let data = {
              personBean: this.personBean,
              personGrBean: this.personGrBean,
              roomNum: this.rm_num,
              credentialCardInfoBeans: [this.credentialCardInfoBeans[index]],
            };
            console.log(data);
              if(this.deviceFlg==0) {
                  getSiteDeviceStatus(data)
                      .then((res) => {
                          if (res.errorCode != "00") {
                              let {data} = res;
                              console.log(">>>>>>>deviceData", data);
                              this.loading = false;
                              this.deviceValueList = data;
                              this.deviceVisible = true;
                          }else {
                              this.handerOutPersonSetUop(data);
                          }
                      })
                      .catch((err) => {
                          console.log(err);
                          this.loading = false;
                      });
              }
              if (this.deviceFlg == 1) {
                  this.deviceFlg = 0;
                  this.handerOutPersonSetUop(data);
              }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

      /**
       * 人员数据下发
        * @param data
       */
    handerOutPersonSetUop(data){
        personSetUp(data)
            .then((res) => {
                console.log(res);
                this.loading = false;
                if (res.errorCode == "00") {
                    // const {
                    //   data: {
                    //     credentialCardInfoBeans: [
                    //       {
                    //         credentialCardBean: { cardId },
                    //       },
                    //     ],
                    //   },
                    // } = res;
                    // console.log(cardId);
                    // // for (let i = 0; i < length; i++) {
                    // //   formerCardId[i] =
                    // //     credentialCardInfoBeans[i].credentialCardBean.cardId;
                    // // }
                    // // this.formerCardId = formerCardId;
                    const {
                        data: {
                            personBean,
                            personGrBean,
                            credentialCardInfoBeans,
                            roomNum,
                        },
                    } = res;
                    personGrBean.id = personGrBean.id.split("_")[0];
                    this.personBean = personBean;
                    this.personGrBean = personGrBean;
                    this.credentialCardInfoBeans = credentialCardInfoBeans;
                    this.rm_num = roomNum;
                    const length = credentialCardInfoBeans.length;
                    if (length == 0) {
                        for (let i = 0; i < 3; i++) {
                            this.credentialCardInfoBeans.push({
                                credentialCardBean: {},
                                accessRoleListBean: [],
                                elevatorAccessRoleListBean: [],
                                fingerVeinBean: {},
                                facePhotoBean: {},
                                consumeCardInfoBean: {},
                            });
                        }
                    } else if (length == 1) {
                        for (let i = 0; i < 2; i++) {
                            this.credentialCardInfoBeans.push({
                                credentialCardBean: {},
                                accessRoleListBean: [],
                                elevatorAccessRoleListBean: [],
                                fingerVeinBean: {},
                                facePhotoBean: {},
                                consumeCardInfoBean: {},
                            });
                        }
                    } else if (length == 2) {
                        for (let i = 0; i < 1; i++) {
                            this.credentialCardInfoBeans.push({
                                credentialCardBean: {},
                                accessRoleListBean: [],
                                elevatorAccessRoleListBean: [],
                                fingerVeinBean: {},
                                facePhotoBean: {},
                                consumeCardInfoBean: {},
                            });
                        }
                    }
                    const formerCardId = this.formerCardId;
                    for (let i = 0; i < length; i++) {
                        formerCardId[i] =
                            credentialCardInfoBeans[i].credentialCardBean.cardId;
                    }
                    this.formerCardId = formerCardId;
                    this.$message.success(res.msg,10);
                    this.$router.go(-1)
                }
                else if(res.errorCode == "217" && res.description != ""){
                    var description = res.description;
                    description = description.split("\\").join("");
                    let data = JSON.parse(description);
                    this.faceErrorMessageVisible=true;
                    this.faceErrorMessageList=data;
                    var title= res.msg;
                    var titleTemp=title.split("】");
                    var titleMsg=titleTemp[0].split("【");
                    if(titleMsg.length>1)
                      this.faceErrorTitle=titleMsg[1];
                }
                else {
                    this.$error({
                        title: res.msg,
                        centered:true
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
            });
    },

    // 递归遍历方法
    recursion(arr) {
      for (let i = 0; i < arr.length; i++) {
        this.arr.push(arr[i].name);
        if (arr[i].children !== null) {
          this.recursion(arr[i].children);
        }else{
          this.personGrId = arr[i].id
        }
      }
    },
    // 打开通行模式弹框
    openTrafficMode(e,value){
      this.trafficModeData = e
      this.trafficModeValue = value
      this.trafficModeVisible = true
    },
    // 通行模式弹框关闭
    trafficModeCancel(){
      this.trafficModeVisible = false
    },
      deviceValueCancel(){
      this.deviceVisible = false
    },
      faceErrorMessageCancel(){
      this.faceErrorMessageVisible = false
    },
    setUpDeviceValueFlg(){
        this.deviceFlg=1;
        this.personSetUp();
    }
  }
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#peopleManagement {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  overflow: hidden;
}
.header {
  padding: 10px 20px;
}
.main {
  height: calc(100% - 123px);
  padding: 0px 20px;
  overflow: auto;
}

.outerframe {
  position: relative;
  border: 1px solid rgba(12, 12, 12, 0.3);
  margin: 10px 0px 30px 0px;
  padding: 20px;
}
.outerframe-title {
  position: absolute;
  top: -14px;
  left: 15px;
  padding: 0px 15px;
  font-size: 16px;
  color: rgba(12, 12, 12, 0.7);
  background-color: #ffffff;
}
.outerframe-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.basicinfo-form {
  min-width: 1000px;
  height: 60px;
  white-space: nowrap;
}
.basicinfo-site {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  letter-spacing: 2px;
  white-space: nowrap;
}
.basicinfo-site-row {
  margin-bottom: 5px;
}
.cardinfo-outerframe {
  border: 1px solid rgba(12, 12, 12, 0.3);
  border-radius: 3px;
  margin-bottom: 20px;
}
.cardinfo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  letter-spacing: 4px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.3);
  background: #f2f4f7;
}
.cardinfo-row {
  display: flex;
  box-shadow: 0px -10px 15px -15px #bdbcbc inset;
}
.cardinfo-row-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  padding: 15px;
}
.cardinfo-row-right {
  display: flex;
  box-shadow: 15px 0px 15px -15px #bdbcbc inset;
  padding-left: 20px;
}
.cardinfo-row-right-bulk {
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 0px;
  margin-right: 15px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.footer button {
  margin-left: 15px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.img {
  width: 100%;
}
a{
  text-decoration:underline
}
</style>

