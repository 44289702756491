<!--人员管理--通行模式详情查看-->
<template>
  <div id="trafficMode" ref="trafficMode">
      <a-modal
      :title="$t('information.a263')"
      centered
      :width="900"
      :visible="visible"
      :getContainer="() => this.$refs.trafficMode"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a51") }}
        </a-button>
      </template>
      <div class="main" v-loading="loading" :element-loading-text="$t('information.a1')">
          <div class="name">
              <span>{{ $t("information.a264") }}</span>
              <span>{{ name }}</span>
          </div>
          <div>
            <a-table
                :columns="column"
                :dataSource="trafficModeData"
                :pagination="pagination"
                :scroll="{x:1200,y:230}"
                :rowKey="(record) => record.id"
            >
          </a-table>
          </div>
      </div>  
    </a-modal>
  </div>
</template>

<script>
import { getTPData } from '../../../api/door'
import global from '@/mixins/global.js'

export default {
    mixins: [global],
    props:{
        visible:{
            type:Boolean
        },
        data:{
            type:Object
        },
        value:{
            type:Number
        }
    },
    data(){
        return{
            loading:false,
            column: [
                {
                id: "1",
                title: "NO",
                dataIndex: "NO",
                customRender: (text, record, index) =>
                    `${
                    (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                    index +
                    1
                    }`,
                width: 60,
                align: "center",
                },
                {
                id: "2",
                title: this.$t("information.a265"),
                dataIndex: "customerName",
                width: 120,
                },
                {
                id: "3",
                title: this.$t("information.a266"),
                dataIndex: "siteName",
                width: 120,
                },
                {
                id: "4",
                title: this.$t("information.a267"),
                dataIndex: "tenantName",
                width: 130,
                },
                {
                id: "5",
                title: this.$t("information.a268"),
                dataIndex: "name",
                width: 130,
                },
                {
                id: "6",
                title: this.$t("information.a269"),
                dataIndex: "credentialReaderName",
                width: 150,
                },
                {
                id: "7",
                title: this.$t("information.a270"),
                dataIndex: "EQUIPMENT_NAME2",
                width: 100,
                },
                {
                id: "8",
                title: this.$t("information.a271"),
                dataIndex: "alertSet",
                customRender: (text, record, index) =>
                    record.alertSet == 0
                    ? this.$t("information.a142")
                    : this.$t("information.a143"),
                width: 100,
                },
                {
                id: "9",
                title: this.$t("information.a272"),
                dataIndex: "scheduleName",
                width: 130,
                },
                {
                id: "10",
                title: this.$t("information.a273"),
                dataIndex: "calendarName",
                width: 100,
                },
            ],
            trafficModeData:[],
            name:''
        }
    },
    watch:{
        // 获取通行模式详情
        visible:{
            handler(newValue){
                if(newValue){
                    this.loading = true
                    const name = this.data.accessRoleList[this.value].name
                    this.name = name
                    const siteId = this.data.siteId
                    const accessRoleId = this.data.accessRoleList[this.value].id
                    const data = { siteId,accessRoleId}
                    getTPData(data)
                    .then(res=>{
                        console.log(res)
                        const { data:{ arDeviceList } } = res
                        this.trafficModeData = arDeviceList
                        this.loading = false
                    })
                    .catch(err=>{
                        console.log(err)
                        this.loading = false
                    })
                }else{
                    return false
                }
            },
            deep:true
        }
    },
    methods:{
        // 弹窗关闭方法
        Cancel() {
        this.$emit("shut");
        },
    }
}
</script>

<style scoped>
.main{
    height: 350px;
}
.name{
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;
}
.name::before{
    content: "";
    display: inline-block;
    width: 4px;
    height: 20px;
    background-color: #7682CE;
    margin-right: 10px;
}
</style>