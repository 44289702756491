<template>
    <div id="peopleManagementDevice" ref="peopleManagementDevice">
        <a-modal
                :title="$t('peopleDevice.a1')"
                centered
                :width="500"
                :visible="visible"
                :getContainer="() => this.$refs.peopleManagementDevice"
                @cancel="Cancel"
                :destroyOnClose="true"
        >
            <template slot="footer">
              <a-button key="submit" type="primary" @click="goHaner">
                  {{$t("peopleDevice.a2")}}
              </a-button>
              <a-button key="back" @click="Cancel">
                  {{$t("peopleDevice.a3")}}
              </a-button>
            </template>

           <div>
               <span v-for="item in deviceValueList">
                   <my-tabletitle  style="background-color: #fbc4c4;font-weight: bold">{{item.siteGrName}}{{" / "}}{{item.siteName}}{{" ( "}}{{item.smartGateWayCode}}{{" )"}}</my-tabletitle>
                   <a-table v-if="item.gwcError==0"
                    :columns="deviceValueListTable"
                    :dataSource="item.flcMesg"
                    :pagination="false"
                    bordered
                    :rowKey="(record) => record.NO"
                   >
                   </a-table>
                   <br>
               </span>
           </div>

        </a-modal>
    </div>
</template>

<script>
    import tabletitle from "../../../components/Title/tabletitle";
    export default {
        name: "peopleManagementDevice",
        props: {
            deviceValueList: {
                type: Array,
            },
            visible: {
                type: Boolean,
            },
        },

     data(){
            return{
                deviceValueListTable:[
                {
                    key:"1",
                    title: "NO",
                    width: 30,
                    align: "center",
                    dataIndex: "NO",
                    ellipsis: true,
                },
                {
                    key:"2",
                    title: "IP",
                    width: 40,
                    align: "center",
                    dataIndex: "IP",
                    ellipsis: true,
                }
                ]
            }
     },

     methods: {
         // 弹窗关闭方法
         Cancel() {
             this.$emit("shut");
         },
         goHaner(){
             this.$confirm({
                 title: this.$t("peopleDevice.a4"),
                 centered: true,
                 onOk: () => {
                     this.$emit("goHander");
                     this.$emit("shut");
                 },
             });
         }
     },

     components: {
         "my-tabletitle": tabletitle,
     },
    }
</script>

<style scoped>
    #peopleManagementDevice {
        width: 300px;
        padding: 0px 10% 20px 20px;
        margin-bottom: 40px;
        white-space: nowrap;
        overflow: hidden;
    }
</style>