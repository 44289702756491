<!--人员管理--有效期限设置-->
<template>
  <div id="timeSet" ref="timeSet">
    <a-modal
      :title="$t('information.a189')"
      centered
      :visible="visible"
      :getContainer="() => this.$refs.timeSet"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a190") }}
        </a-button>
        <a-button key="submit" type="primary" @click="timeSet">
          {{ $t("information.a191") }}
        </a-button>
      </template>
      <div>
        <a-row
          :gutter="32"
          type="flex"
          align="middle"
          style="margin-bottom: 10px"
        >
          <a-col :span="6"> {{ $t("information.a192") }} </a-col>
          <a-col :span="14">
            <a-date-picker
              v-model="activationTime"
              :disabled-date="disabledStartDate"
            />
          </a-col>
        </a-row>
        <a-row :gutter="32" type="flex" align="middle">
          <a-col :span="6"> {{ $t("information.a193") }} </a-col>
          <a-col :span="14">
            <a-date-picker
              v-model="expirationTerm"
              :disabled-date="disabledEndDate"
            />
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    value: {
      type: Number,
    },
  },
  data() {
    return {
      activationTime: moment(),
      expirationTerm: moment(),
    };
  },
  watch: {
    visible: {
      handler(newVuale) {
        const value = this.value;
        const data = this.data;
        const _val = JSON.stringify(data);
        const val = JSON.parse(_val);
        let activationTime = val.credentialCardBean.activationTime;
        let expirationTerm = val.credentialCardBean.expirationTerm;
        if (value == 2) {
          activationTime = val.fingerVeinBean.activationTime;
          expirationTerm = val.fingerVeinBean.expirationTerm;
        }
        if (activationTime == null) {
          this.activationTime = null;
        } else {
          this.activationTime = moment(activationTime);
        }
        if (expirationTerm == null) {
          this.expirationTerm = null;
        } else {
          this.expirationTerm = moment(expirationTerm);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    moment,
    Cancel() {
      this.$emit("shut");
    },
    timeSet() {
      const data = {
        activationTime: this.activationTime.format("YYYY-MM-DD"),
        expirationTerm: this.expirationTerm.format("YYYY-MM-DD"),
      };
      this.$emit("dataChange", data);
    },
    disabledStartDate(startValue) {
      const endValue = this.expirationTerm;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.activationTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
  },
};
</script>

<style>
</style>