<!--人员管理--指静脉信息获取-->
<template>
  <div id="fingerSelect" ref="fingerSelect">
    <a-modal
      :title="$t('information.a194')"
      centered
      :width="400"
      :visible="visible"
      :getContainer="() => this.$refs.fingerSelect"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a190") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="fingerSet"
          :disabled="disabled1"
        >
          {{ $t("information.a191") }}
        </a-button>
      </template>
      <div>
        <div class="finger">
          <div>
            <span>{{ $t("information.a195") }}</span>
            <span>{{ fingerVein1 }}</span>
          </div>
          <a-button
            @click="readFingerprints(1)"
            type="primary"
            ghost
            :disabled="disabled"
          >
            {{ $t("information.a196") }}
          </a-button>
        </div>
        <div class="finger">
          <div>
            <span>{{ $t("information.a197") }}</span>
            <span>{{ fingerVein2 }}</span>
          </div>
          <a-button
            @click="readFingerprints(2)"
            type="primary"
            ghost
            :disabled="disabled"
          >
            {{ $t("information.a196") }}
          </a-button>
        </div>
        <div class="success" v-show="show">
          <span> {{ $t("information.a198") }}</span>
        </div>
        <div class="error" v-show="!show">
          <span>{{ $t("information.a199") }}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      fingerVeinData1: "",
      fingerVeinData2: "",
      threshold: 1,
      show: false,
      number1: 0,
      number2: 0,
    };
  },
  watch: {
    visible: {
      handler(newValue) {
        console.log(newValue);
        if (newValue == true) {
          var strHttpBaseUrl = "https://localhost:10180/webapp/sample2/";
          $.ajax({
            url: strHttpBaseUrl + "FVCollateTime?jsoncallback=?",
            dataType: "jsonp",
            method: "GET",
            success: (data_input) => {
              console.log(data_input);
              var data = JSON.parse(data_input);
              this.show = data.IsSentOK;
            },
            error: () => {
              // alert("连接出错 !");
            },
          });
        }
      },
    },
  },
  computed: {
    fingerVein1() {
      let value = this.number1;
      if (value == 0) {
        return this.$t("information.a200");
      } else if (value == 1) {
        return this.$t("information.a201");
      } else if (value == 2) {
        return this.$t("information.a202");
      } else if (value == 3) {
        return this.$t("information.a203");
      }
    },
    fingerVein2() {
      let value = this.number2;
      if (value == 0) {
        return this.$t("information.a200");
      } else if (value == 1) {
        return this.$t("information.a201");
      } else if (value == 2) {
        return this.$t("information.a202");
      } else if (value == 3) {
        return this.$t("information.a203");
      }
    },
    disabled1() {
      let value1 = this.fingerVeinData1;
      let value2 = this.fingerVeinData2;
      if (value1 == "" || value2 == "") {
        return true;
      } else {
        return false;
      }
    },
    disabled() {
      let value = this.show;
      if (value == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    Cancel() {
      this.$emit("shut");
    },
    readFingerprints(value) {
      if (value == 1) {
        this.number1 = 1;
      } else {
        this.number2 = 1;
      }
      let strHttpBaseUrl = "https://localhost:10180/webapp/sample2/";
      $.ajax({
        url: strHttpBaseUrl + "FVTemplateCollect?jsoncallback=?",
        type: "GET",
        dataType: "jsonp",
        data: { IsCollectTemplate: true },
        success: (data_input) => {
          let data = JSON.parse(data_input);
          if (data.IsSentOK == true) {
            let { VCIm_VCimage_str, VCIm_quality } = data;
            if (value == 1) {
              this.fingerVeinData1 = VCIm_VCimage_str;
              this.number1 = 3;
            } else {
              this.fingerVeinData2 = VCIm_VCimage_str;
              this.number2 = 3;
            }
            this.threshold = VCIm_quality;
            this.$message.success(data.ErrMsg);
          } else {
            if (value == 1) {
              this.number1 = 2;
            } else {
              this.number2 = 2;
            }
            this.$message.error(data.ErrMsg);
          }
        },
        error: (jqHr, textStatus) => {
          this.$message.error(textStatus);
        },
      });
      return false;
    },
    fingerSet() {
      const data = {
        fingerVeinData1: this.fingerVeinData1,
        fingerVeinData2: this.fingerVeinData2,
        threshold: this.threshold,
        registerType: -1,
      };
      this.$emit("dataChange", data);
      this.fingerVeinData1 = "";
      this.fingerVeinData2 = "";
      this.number1 = 0;
      this.number2 = 0;
      this.$emit("shut");
    },
  },
};
</script>

<style>
.finger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.success {
  font-size: 16px;
  color: green;
}
.error {
  font-size: 16px;
  color: red;
}
</style>